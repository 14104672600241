import React, { lazy, Suspense } from 'react';
// import { BrowserRouter as Router, Route } from 'react-router-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// development variables are established by the npm "start" script
// staging/production variables are pm2-created

const Home = lazy(() => import('./pages/home/Home'));

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={''}>
        <Routes>
          <Route path='/' element={<Home />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
